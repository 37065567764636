
import { computed, defineComponent, onMounted, reactive, ref } from 'vue'
import AccountSummary from '@/views/crafted/mc-superadmin/Accounts/AccountSummary.vue'
import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb'
import Swal from 'sweetalert2/dist/sweetalert2.js'
import { Actions, Mutations, MutationsActions } from '@/store/enums/StoreEnums'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { Account } from '@/store/modules/AccountModule'
import { CompaniesPayload } from '@/store/modules/CompanyModule'

export interface NewAccount {
    firstName: string,
    lastName: string,
    email: string,
    phone?: string,
    role: number,
    company?: string,
    type?: string
}

export default defineComponent({
  name: 'add-account',
  components: {
    AccountSummary
  },
  setup () {
    onMounted(() => {
      setCurrentPageBreadcrumbs('Add Account', ['Accounts'])
    })
    const store = useStore()
    const router = useRouter()
    const formRef = ref<null | HTMLFormElement>(null)
    const newTargetModalRef = ref<null | HTMLElement>(null)
    const loading = ref(false)

    const targetData = reactive({
      accountUuid: '',
      email: '',
      firstName: '',
      lastName: '',
      phone: '',
      company: { uuid: '', name: '' }
    }) as Account

    const rules = computed(() => {
      return {
        firstName: [
          {
            required: true,
            message: 'Please input account first name',
            trigger: 'blur'
          }
        ],
        lastName: [
          {
            required: true,
            message: 'Please input account first name',
            trigger: 'blur'
          }
        ],
        email: [
          {
            required: true,
            message: 'Please input account email',
            trigger: 'blur'
          }
        ],
        roleId: [
          {
            required: true,
            message: 'Please select the role',
            trigger: 'blur'
          }
        ],
        'company.uuid': [
          {
            required: targetData.roleId !== '' && targetData.roleId !== 1,
            message: 'Please select the company',
            trigger: 'blur'
          }
        ],
        phone: [
          {
            required: true,
            message: 'Please use a valid phone number',
            trigger: 'blur'
          }
        ]
      }
    })

    const submit = async (values) => {
      if (!formRef.value) {
        return
      }
      let formValidation = false
      formRef.value.validate((valid) => {
        if (!valid) {
          formValidation = false
          return false
        }
        loading.value = true
        formValidation = true
      })
      if (!formValidation) {
        await store.commit(Mutations.SET_COMPANY_ERRORS, {})

        Swal.fire({
          text: 'Sorry, looks like there are some errors detected, please try again.',
          icon: 'error',
          buttonsStyling: false,
          confirmButtonText: 'Ok, got it!',
          customClass: {
            confirmButton: 'btn btn-primary'
          }
        })
        loading.value = false

        return
      }

      // build the payload required to create new account
      const payload = reactive({
        firstName: targetData.firstName,
        lastName: targetData.lastName,
        email: targetData.email,
        phone: targetData.phone,
        type: targetData.type,
        roleId: targetData.roleId
      }) as unknown as NewAccount

      if (targetData.company?.uuid) {
        payload.company = targetData.company.uuid
      }
      store.dispatch(Actions.API_CREATE_NEW_ACCOUNT, payload).then((response) => {
        Swal.fire({
          text: 'You have successfully created the account ' + payload.firstName + ' ' + payload.lastName,
          icon: 'success',
          buttonsStyling: false,
          confirmButtonText: 'Ok, got it!',
          customClass: {
            confirmButton: 'btn fw-bold btn-light-primary'
          }
        }).then(function () {
          loading.value = false
          // Go to accounts page after successfully login
          router.push({ name: 'accounts' })
        })
      }).catch(() => {
        loading.value = false
      })
    }

    const companiesAsOptions = computed(() => store.getters.getCompaniesAsOptions)
    const remoteMethod = (query: string) => {
      if (query !== '') {
        loading.value = true
        // clear the initial search results
        store.commit(Mutations.SET_COMPANIES, [])
        setTimeout(() => {
          loading.value = false
          const filter: CompaniesPayload = { filter: { limit: 100, offset: 0, label: query } }
          store.dispatch(MutationsActions.API_GET_MUTATE_COMPANIES, filter)
        }, 200)
      } else {
        const filter: CompaniesPayload = { filter: { limit: 100, offset: 0 } }
        store.dispatch(MutationsActions.API_GET_MUTATE_COMPANIES, filter)
      }
    }

    const onSelectFocused = () => {
      // on focus it should search for existing companies
      const filter: CompaniesPayload = { filter: { limit: 100, offset: 0 } }
      store.dispatch(MutationsActions.API_GET_MUTATE_COMPANIES, filter)
    }

    const onCompanySelectorChanged = (value) => {
      // set the name of the company to be used inside the summary
      targetData.company!.name = companiesAsOptions.value.find(company => company.value === value).label
    }

    return {
      companiesAsOptions,
      targetData,
      loading,
      formRef,
      rules,
      newTargetModalRef,
      remoteMethod,
      submit,
      onSelectFocused,
      onCompanySelectorChanged
    }
  }
})
