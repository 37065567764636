
import { computed, defineComponent } from 'vue'
import { getCountryName, getRoleName } from '@/core/helpers/assets'
import { Account } from '@/store/modules/AccountModule'

export default defineComponent({
  name: 'account-summary',
  components: {},
  emit: ['submit'],
  props: {
    loading: { type: Boolean, default: false },
    targetData: { type: Object }
  },
  setup (props, { emit }) {
    const onSubmit = () => {
      emit('submit')
    }
    const details = computed(() => props.targetData) as unknown as Account
    return {
      details,
      getCountryName,
      onSubmit,
      getRoleName
    }
  }
})
